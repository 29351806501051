<template>
  <div>
    <el-card shadow="always" class="box-card">
      <div class="head">9N系列</div>
      <el-row>
        <el-col :span="3">描述</el-col>
        <el-col :span="21">
          <el-input type="text" v-model="dataList[0].descContent"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">图片描述</el-col>
        <el-col :span="21">
          <el-input type="textarea"  v-model="dataList[0].imgTitle"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">图片小字</el-col>
        <el-col :span="21">
          <el-input type="textarea"  v-model="dataList[0].imgDesc"></el-input>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="head">7N系列</div>
      <el-row>
        <el-col :span="3">描述</el-col>
        <el-col :span="21">
          <el-input type="text" v-model="dataList[1].descContent"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">图片描述</el-col>
        <el-col :span="21">
          <el-input type="textarea"  v-model="dataList[1].imgTitle"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">图片小字</el-col>
        <el-col :span="21">
          <el-input type="textarea"  v-model="dataList[1].imgDesc"></el-input>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="head">高压系列</div>
      <el-row>
        <el-col :span="3">描述</el-col>
        <el-col :span="21">
          <el-input type="text" v-model="dataList[2].descContent"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">图片描述</el-col>
        <el-col :span="21">
          <el-input type="textarea"  v-model="dataList[2].imgTitle"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">图片小字</el-col>
        <el-col :span="21">
          <el-input type="textarea"  v-model="dataList[2].imgDesc"></el-input>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="head">GC系列</div>
      <el-row>
        <el-col :span="3">描述</el-col>
        <el-col :span="21">
          <el-input type="text" v-model="dataList[3].descContent"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">图片描述</el-col>
        <el-col :span="21">
          <el-input type="textarea"  v-model="dataList[3].imgTitle"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">图片小字</el-col>
        <el-col :span="21">
          <el-input type="textarea"  v-model="dataList[3].imgDesc"></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="end">
        <el-button size="medium" type="primary" style="min-width: 120px" @click="save">保存</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {getCard, updateCard} from "@/plugins/api";

export default {
  name: 'Product',
  data() {
    return {
      dataList: [
      ]
    }
  },
  methods: {
    async getCard() {
      let res = await getCard();
      console.log(res)
      this.dataList = res;
    },
    async save() {
      this.dataList.map(async (x) => {
        await updateCard({
          id: x.id,
          title: x.title,
          desc_content: x.descContent,
          img_title: x.imgTitle,
          img_desc: x.imgDesc,
        })
      })
      this.getCard()
    }
  },
  created() {
    this.getCard()
  }
}
</script>

<style scoped>
.head {font-size: 21px; margin-bottom: 10px; color: #1c6ca1}
.el-row {margin-bottom: 10px}
</style>
